import React,{ useEffect } from 'react';
import Header from '../Header.js';
import './SignUp.css';
import Footer from "../Reusable/Footer.js";
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useState } from "react";

const SignUp =() =>{
  
  const [postsNumber, setNumber] = useState(1);
  useEffect(()=>{
    firebase.app();

    const db = firebase.firestore();
    db.collection("subscribers").get().then(function(querySnapshot) {      
      setNumber(querySnapshot.size + 1);
    });
  })
  function handleSubmit(){
    firebase.app();

    const db = firebase.firestore();
    db.collection("subscribers").get().then(function(querySnapshot) {      
      setNumber(querySnapshot.size + 1);
    });
    db.collection('subscribers').doc(`${postsNumber}`).set({
        Name: document.getElementById("name").value,
        Phone: document.getElementById("phone").value,
        Email: document.getElementById("email").value,
    }).then(function () {
        alert("Вие се записахте успешно.");
    }).catch(function (err) {
      console.log(err);
    })
  }
return(
    <div className="signUpContainer">
      <Header></Header>
      <div className = "formContainer">
        <h1>Запишете се, за да получавате емайли от нас.</h1>

        <div className = "inputContainer">
        <div className = "form"> 
          <label>Email:</label>
          <input type = "email" placeholder="Email" id = "email" required></input>
        </div>

        <div className = "form"> 
          <label>Телефон:</label>
          <input type = "number" placeholder="Телефон" id = "phone"></input>
        </div>

        <div className = "form"> 
          <label>Име:</label>
          <input type = "text" placeholder="Име" id = "name"></input>
        </div>
        </div>

        <button className = "signUp" onClick = {handleSubmit}>Участвай</button>

      </div>
    </div>
    )
}

export default SignUp;