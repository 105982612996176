import React from 'react';
import firebase from "firebase";
import Header from '../Header.js';
import './Positions.css';
import { Link, Route, BrowserRouter, Router} from 'react-router-dom';
import logo from '../../assets/kod-logo.svg';
import ReactPaginate from 'react-paginate';
import ReusablePost from '../Reusable/ReusablePost.js';

class Positions extends React.Component{
    constructor(props){
        super(props);
        this.state = {titles: [], texts: [], imgs: [], post:{}, hoverImgId: []}
        this.getPosts = this.getPosts.bind(this);
    }
    getPosts = () =>{
        let currentComponent = this;
        let docData = {};
        const db = firebase.firestore();
        for(let i = 0 ; i<4; i++){
            let firebaseImg = db.collection('positions').doc(`${i + 1}`);
            firebaseImg.get().then(function (doc) {
                if(doc.exists){
                    docData = doc.data();

                    var img = document.getElementById(`img${i}`);
                    img.src = doc.data().url;
                }
                else{
                    console.log("no such doc exists");
                }
            }).catch(function (err) {
                console.log(err);
            });
        }
        
        let myPostsArr = [];
        db.collection('positions').get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                myPostsArr.push(doc.data());

            });
        }).then(function (){
            for(let i = 0 ; i< myPostsArr.length;i++){
                let newTitle = myPostsArr[i].title
                currentComponent.setState(state => ({
                    titles: state.titles.concat([myPostsArr[i].title]),
                    texts: state.texts.concat([myPostsArr[i].text]),
                    imgs: state.imgs.concat([myPostsArr[i].url])
                }));
            }
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
    }
    componentDidMount() {
        this.getPosts();
    }
    getElementIdNumber(mouse){
        let result = 0;
        let mouseTarget = mouse.target;
        if(mouseTarget.hasChildNodes()){
            if(mouseTarget.firstChild.nodeName == "H1")
            {
                let elementImg = mouseTarget.firstChild.nextSibling.firstChild;
                result = elementImg.id[3]
            }
            else{
                // console.log('Handle this logic:Placed 0 for now');
            }
        }
        else{
            result = mouse.target.id[3]
        }
        this.setState({hoverImgId:result});
        return result;
    }
    render(){
        return(
            <BrowserRouter>
            <Route path = "/positions">
            <div className="news">
                <Header></Header>
                <div className = "newsContainer">
                        <div className = "post" onMouseEnter = {(mouse) => this.getElementIdNumber(mouse)}>
                            <h1 className = "newsTitle">{this.state.titles[0]}</h1>
                            <Link to = {`/${this.state.titles[this.state.hoverImgId]}`}><img id = "img0"></img></Link>
                        </div>
                    <div className = "post" onMouseEnter = {(mouse) => this.getElementIdNumber(mouse)}>
                        <h1 className = "newsTitle">{this.state.titles[1]}</h1>
                        <Link to = {`/${this.state.titles[this.state.hoverImgId]}`}><img id = "img1"></img></Link>
                    </div>
                    <div className = "post" onMouseEnter = {(mouse) => this.getElementIdNumber(mouse)}>
                        <h1 className = "newsTitle">{this.state.titles[2]}</h1>
                          <Link to = {`/${this.state.titles[this.state.hoverImgId]}`}><img id = "img2"></img></Link>
                    </div>
                    <div className = "post" onMouseEnter = {(mouse) => this.getElementIdNumber(mouse)}> 
                        <h1 className = "newsTitle">{this.state.titles[3]}</h1>
                       <Link to = {`/${this.state.titles[this.state.hoverImgId]}`}><img id = "img3"></img></Link>
                    </div>
                </div>
                {/* <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                /> */}
                <div className="links">
                    <a href="">Поверителност и Условия</a>
                    <a href="">Контакти</a>
                    <a href="https://www.facebook.com/about.kod">Facebook</a>
                    <a href="/"><img src ={logo}></img></a>
            </div>
            </div>
            </Route>
            <Route path={`/${this.state.titles[this.state.hoverImgId]}`}>
                <ReusablePost text = {this.state.texts[this.state.hoverImgId]} title = {this.state.titles[this.state.hoverImgId]} url = {this.state.imgs[this.state.hoverImgId]}></ReusablePost>
            </Route>
            </BrowserRouter>
            
        )
    }
}

export default Positions;