import firebase from 'firebase';
import 'firebase/storage';

export const app = firebase.initializeApp({
  apiKey: "AIzaSyC2QrFpt1UVQ-9uR6PrKkK6BsRcLU7POQs",
  authDomain: "kodproject-3a409.firebaseapp.com",
  databaseURL: "https://kodproject-3a409.firebaseio.com",
  projectId: "kodproject-3a409",
  storageBucket: "kodproject-3a409.appspot.com",
  messagingSenderId: "41530032909",
  appId: "1:41530032909:web:416454ddf0137a00a32644",
  measurementId: "G-RSBSDE1V9L"
});