import React from 'react';
import Header from '../Header.js';
import './ReusablePost.css';
import Footer from './Footer.js';

class reusablePost extends React.Component{
  constructor(props){
      super(props);
  }
 
  render(){
    //scroll to the top
    document.body.scrollTop = document.documentElement.scrollTop = 0;
      return(
          <div className="reusablePost">
              <Header></Header>
              <div className = "text">
                  <div className = "innerText"><span style = {{borderBottom : "3px solid red"}}>{this.props.title}</span>
                      <img src = {this.props.url} className= "reusableImg"></img>
                      <p style = {{fontSize : "0.4em",textAlign: "left", marginTop: "2em", padding: "1em"}}>{this.props.text}</p>
                  </div>
                </div>
                <Footer></Footer>
          </div>
      )
  }
}

export default reusablePost;