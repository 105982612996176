import React from 'react';
import {BsGrid3X3GapFill} from "react-icons/bs";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from '../assets/kod-logo.svg';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import { useState } from "react";

const Header =() =>{
    const [show, setShow] = useState(false);
return(
    <div className="App">
        <header className="header">
            <button className="menuBtn"  onClick={() => setShow(true)}><BsGrid3X3GapFill size={40}/></button>
            <a href="/" style={{margin: "auto"}}><img src={logo} className='mainLogo' alt="logo"/></a>
            <Link to = "/login">
                <button className="loginBtn">Логин като админ</button>
            </Link>
        </header>
        <Navbar className='navbar'>
            <Nav>
                <Nav.Link href="/kod">За КОД</Nav.Link>
                <Nav.Link href="/standpoint">Становища и предложения</Nav.Link>
                <Nav.Link href="/positions">Позиции</Nav.Link>
                <Nav.Link href="/news">Новини</Nav.Link>
                <Nav.Link href="/contacts">Контакти</Nav.Link>
            </Nav>
    </Navbar>

    <Modal
        show={show}
        onHide={() => setShow(false)}
        className="modal-container custom-map-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Бързо Навигиране
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Link to = "/kod">
                <button className="modalLink">За КОД</button>
            </Link>
            <br></br>
            <Link to = "/standpoint">
                <button className="modalLink">Становища и предложения</button>
            </Link>
            <br></br>
            <Link to = "/positions">
                <button className="modalLink">Позиции</button>
            </Link>
            <br></br>
            <Link to = "/news">
                <button className="modalLink">Новини</button>
            </Link>
            <br></br>
            <Link to = "/contacts">
                <button className="modalLink">Контакти</button>
            </Link>
        </Modal.Body>
      </Modal>
    </div>
    )
}

export default Header;