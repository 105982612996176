import logo from './assets/kod-logo.svg';
import moskov from './assets/moskov.jpg';
import kanov from './assets/kanov.jpg';
import ralitza from './assets/ralitza.png';
import valentin from './assets/valentin.jpg';
import stankov from './assets/stankov.jpg';
import sharkov from './assets/sharkov.jpg';
import dimitar from './assets/dimitar.jpg';
import jotina from './assets/jotina.png';
import pepa from './assets/pepa.png';
import stefanov from './assets/stefanov.png';
import rositza from './assets/rositsa.png';
import {BrowserRouter as Router,Route} from 'react-router-dom';
import Header from "./components/Header.js";
import Login from "./components/Login.js";
import News from "./components/News/News.js";
import Standpoint from "./components/Standpoint/Standpoint.js";
import Positions from "./components/PoliticalPosition/Postions.js";
import Contacts from "./components/Contact/Contact.js";
import Kod from "./components/kod/kod.js";
import { Link} from 'react-router-dom';
import SignUp from './components/SignUpPage/SignUp.js';
import AdminSub from './components/AdminComponents/adminSubscribers/adminSubscribers.js';
import './App.css';
import PrivateRoute from './components/privateRoute.js';
import {app} from "./components/base.js";

import firebase from "firebase";
import 'firebase/firestore';
import 'firebase/auth';

import adminHome from './components/AdminComponents/adminHome/adminHome.js';
import adminNews from './components/AdminComponents/adminNews/adminNews';
import AdminPositions from './components/AdminComponents/adminPositions/adminPositions';

firebase.app(); // if already initialized, use that one

const auth = firebase.auth();
const firestore = firebase.firestore();

function App() {
  return (
    <Router>
      <Route path = '/' exact={true}>
    <div className="App">
      <Header></Header>
      <div className="members">
        <h2>Учредители</h2>
          <ul className="member-cards">

            <li> 
              <span className="memberImg"><img src={moskov} className='imgCircle'></img></span> 

              <div>
                <h3 className="memberName">Петър Москов</h3>
                <p className="bio">Роден в София, в семейство със стари демократични традиции и културни увлечения. Завършва Медицина в Медицински университет – София, специалност Анестезиология и интензивно лечение. Специализира във Франция, Великобритания, Испания и САЩ. Работи като старши асистент в УМБАЛ „Св. Анна“ АД в Клиниката по анестезиология и интензивно лечение. Член е на Инициативния форум за създаване на нова дясна партия през 2004 г. и учредител на ДСБ. През 2007 г. е избран за член и на Националното ръководство на партията. През 2009 г. става съпредседател на предизборния щаб на Синята коалиция. През 2013 г. е избран за заместник-председател на ДСБ. На предсрочните парламентарни избори през октомври 2014 г. е избран за депутат в 43-то НС. Избран за министър на здравеопазването на 7 ноември 2014 г. от 43-то Народно събрание. </p>
              </div>
            </li>

            <li> 
              <span className="memberImg"><img src={kanov} className='imgCircle'></img></span> 

              <div>
                <h3 className="memberName">Любомир Канов</h3>
                <p className="bio">Д-р Любомир Канов е психиатър и писател. Завършил медицина в София в 1971 г, специализирал психиатрия и работил до 1977, когато е атестуван и осъден за контрареволюционна дейност, целяща подкопаването на социализма у нас и в СССР. Излежава присъдата в Старозагорския Затвор. Емигрира в Канада и САЩ през 1984 г, където отново практикува като лекар и американски психиатър. Работи като директор на Клиника за психично здраве и като частно практикуващ до 2016 г, когато се завръща в България. Отличен е с диплома “Top American Psychiatrists” за 2004 г. Публикувал е пет белетристични книги и е носител на Националната награда на българските писатели за 1992г. Женен, има един син. </p>
              </div>
            </li>

            <li> 
              <span className="memberImg"><img src={ralitza} className='imgCircle'></img></span> 

              <div>
                <h3 className="memberName">Ралица Кирилова</h3>
                <p className="bio">Занимава се активно с образование – създава и реализира проекти за подобряване на качеството на образованието, изследва и анализира образователните политики в България. Участва в редица изследвания за паметта от годините на социализма и отраженията на тоталитарния режим в съвременното общество. Изследовател в Центъра за анализ и управление на риска. „За последните 20 години бях служител, бях специалист, управлявах предизборни кампании, управлявах и компании, бях предприемач. Този опит ми помогна да осъзная, че не е достатъчно да допринасям само за Брутния вътрешен продукт – имам и искам да дам много повече за добруването на обществото.“ </p>
              </div>
            </li>

            <li> 
              <span className="memberImg"><img src={sharkov} className='imgCircle'></img></span> 

              <div>
                <h3 className="memberName">Ваньо Шарков</h3>
                <p className="bio">Ваньо Шарков е български лекар и политик, народен представител в 40-то и 41-то Народно събрание. Заместник-министър на здравеопазването в кабинетът от 2014 г. Роден на 25 септември 1962 година в Белоградчик. През 1988 година завършва Медицинска академия в Плевен. Има и магистърска степен по Здравен мениджмънт. В периода от 1999 до 2002 година е директор на МБАЛ – Свищов. От 2002 до 2004 година е директор на Центъра за спешна медицинска помощ София област.</p>
              </div>
            </li>

            <li> 
              <span className="memberImg"><img src={stefanov} className='imgCircle'></img></span> 

              <div>
                <h3 className="memberName">Боян Стефанов</h3>
                <p className="bio">Председател на Младежки Консервативен Клуб. МКК е създаден през 1999 г. Включва широк кръг от млади общественици и представители на академичните среди, споделящи консервативни, християнски и патриотични ценности. По традиция, клубът приема членове след преминаване през сложна процедура за прием. След навършване на 40 години те остават членове на клуба, но без право да участват в управителните и контролните му органи.</p>
              </div>
            </li>

            <li> 
              <span className="memberImg"><img src={rositza} className='imgCircle'></img></span> 

              <div>
                <h3 className="memberName">Росица Рачева</h3>
                <p className="bio">Росица Рачева е магистър по Социология в (СУ), магистър по Клинична психология (НБУ), магистър по Социални интервенции основани на доказателства (University of Oxford) и доктор по Педагогическа и възрастова психология (БАН, Институт за изследване на населението и човека). Има дългогодишен опит в индивидуалното консултиране и работа с групи, създаването и управлението на програми в социалната сфера. През последните години  консултира, супервизира и мониторира проекти и програми със здравна и социална насоченост. Има дългогодишен научно-изследователски опит,  насочен предимно към конструиране и адаптиране на инструменти за оценка на когнитивни и личностни характеристики и редица публикации в български и международни издания. Ангажираността и с политика започва през 2005 година, когато става член на Демократи за Силна България. Била е член на Софийското и Националното ръководство но партията през периода 2012 – 2016 г.</p>
              </div>
            </li>

            <li> 
              <span className="memberImg"><img src={jotina} className='imgCircle'></img></span> 

              <div>
                <h3 className="memberName">Цветанка Йотина</h3>
                <p className="bio">Г-жа Цветанка Йотина е родена на 26 юни 1953, в Кюстендил. По образование е икономист. Омъжена, с три деца. Кмет е на Община Мирково от 2007 г. до настоящия момент. Тя е човек, който се бори за това, което иска и чрез действията си показва, че всичко е възможно. Има ясна представа за желаните бъдещи резултати, за начините за тяхното постигане и най-важното – със знание и умения организира и мотивира хората така, че те да следват избрания от нея път като свой. Има способността да показва добра преценка и разбиране на ситуациите. Човек, който може да разбере всеки. Думата, която напълно описва г-жа Йотина е ЛИДЕР</p>
              </div>
            </li>
            
            <li> 
              <span className="memberImg"><img src={pepa} className='imgCircle'></img></span> 

              <div>
                <h3 className="memberName">Пепа Деведжиева</h3>
                <p className="bio">Финансист и икономист. Родена в гр. Пловдив, завършва средно образование в Гимназия „Св. Кирил и Методи“. 2005 г. получава степен Бакалавър по Публична администрация, 2009 г. защитава бакалвърска степен по Стопанско управление, а 2010 г. надгражда образованието си със степен магистър по Финансов мениджмънт – всички степени придобити в ПУ Пасий Хилендарски. Била управител седем година на „Северна Индустриална Зона“ ООД, създава свои търговски дружества, с които работи на сводния пазар, като предприемач. Демократ и технократ! Била член на ПП ДСБ и неин съучредител, член на НР на ДСБ. В мандат 2015 – 2019 г., общински съветник и председател на групата на РБ, зам.председател на ОбС Пловдив. </p>
              </div>
            </li>

            <li> 
              <span className="memberImg"><img src={dimitar} className='imgCircle'></img></span> 

              <div>
                <h3 className="memberName">Димиър Бъчваров</h3>
                <p className="bio">Финансист и икономист. Роден на 12 януари 1950 г., във Видин. Завършва Автомобилно инженерство и следдипломна квалификация в УНСС. Специализира по макроикономика в Харвард, САЩ. 1997-2001 г. е Директор на дирекция „Икономическа политика“ в правителството на Иван Костов. Учредител на ДСБ. През 2007 г. става заместник-председател на ДСБ и Общински съветник от ДСБ.</p>
              </div>
            </li>

            <li> 
              <span className="memberImg"><img src={valentin} className='imgCircle'></img></span> 

              <div>
                <h3 className="memberName">Валентин Павлов</h3>
                <p className="bio">Д-р Валентин Павлов завършва стоматология през 1996 г. в Стоматологичния факултет към Медицинския университет, София. През 2002 г. придобива специалност орална хирургия. Тогава създава и първата клиника. Има многобройни специализации в Германия и Австрия, както и участие в курсове за допълнително усъвършенстване в областта на хирургията и оралната имплантология.</p>
              </div>
            </li>

            <li> 
              <span className="memberImg"><img src={stankov} className='imgCircle'></img></span> 

              <div>
                <h3 className="memberName">Момчил Станков</h3>
                <p className="bio">Административен директор на Русенски университет Ангел Кънчев, филиал Видин. От 2014 до 2017 г. е областен управител на област Видин. От 2008 до 2014 е общински съветник в гр. Видин. Завършил магистратури по Право и Международни отношения в НБУ. Практикуващ адвокат от 2001 до 2014 г.</p>
              </div>
            </li>

          </ul>
      </div>
      <div className = "footer">
        <h2 className="join">Участвай</h2>
        <Link to="/signUp"><button className="subscribeBtn">Запишете се</button></Link>
      </div>
      <div className="links">
        <a href="">Поверителност и Условия</a>
        <a href="">Контакти</a>
        <a href="https://www.facebook.com/about.kod">Facebook</a>
        <a href="/"><img src ={logo}></img></a>
      </div>
    </div>
    </Route>

    <Route path = '/login'>
      <Header></Header>
      <Login></Login>
    </Route>

    <Route path = '/news'>
      <News></News>
    </Route>

    <Route path = '/standpoint'>
      <Standpoint></Standpoint>
    </Route>

    <Route path = '/positions'>
      <Positions></Positions>
    </Route>
    
    <Route path = '/contacts'>
      <Contacts></Contacts>
    </Route>
    <Route path = '/kod'>
      <Kod></Kod>
    </Route>
    <Route path = '/signUp'>
      <SignUp></SignUp>
    </Route>

    <PrivateRoute component={adminHome} path="/adminHome" exact />
    <PrivateRoute component={adminNews} path="/adminNews" exact />
    <PrivateRoute component={AdminPositions} path="/adminPositions" exact />
    <PrivateRoute component={AdminSub} path="/adminSubscribers" exact />
    </Router>
  );
}

export default App;
