import React from 'react';
import Header from '../Header.js';
import './Contacts.css';
import Footer from "../Reusable/Footer.js";

const Contact =() =>{
return(
    <div className="contactContainer">
      <Header></Header>
      <div className = "contactInfoContainer">
        <ul className = "ulStyle">
          <li>
            Email: team@bulgarianconservatives.eu
          </li>
          <li>
            Телефон: 089 737 4977
          </li>
          <li>
            Адрес:  адрес бул. Евлоги и Христо Георгиеви N9, ет. 2
          </li>
          <li>
             <a href="https://www.facebook.com/about.kod">Facebook</a>
          </li>
        </ul>
      </div>
      <Footer></Footer>
    </div>
    )
}

export default Contact;