import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from '../../../assets/kod-logo.svg';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import { useState } from "react";
import {logout} from '../../utils/index.js';
import { useHistory } from "react-router-dom";
import {BsGrid3X3GapFill} from "react-icons/bs";
import './adminHeader.css';

const Header =() =>{
    let history = useHistory();
    const [show, setShow] = useState(false);

    function handleLogOut() {
      logout();
      history.push("/");
    }
return(
    <div className="adminHeader">
        <header className="header">
            <button className="menuBtn"  onClick={() => setShow(true)}><BsGrid3X3GapFill size={40}/></button>
            <a href="/adminHome" style={{margin: "auto"}}><img src={logo} className='mainLogo' alt="logo"/></a>
            <button className="logoutBtn" onClick = {handleLogOut}>Log out</button>
        </header>
        <Navbar className='adminNavbar'>
            <Nav>
                <Nav.Link href="/adminPositions">Позиции</Nav.Link>
                <Nav.Link href="/adminNews">Новини</Nav.Link>
                <Nav.Link href="/adminSubscribers">Записани</Nav.Link>
            </Nav>
    </Navbar>
    <div className = "adminControl">
      Админски контролен панел
    </div>
    <Modal
        show={show}
        onHide={() => setShow(false)}
        className="modal-container custom-map-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Бързо Навигиране
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Link to = "/positions">
                <button className="modalLink">Позиции</button>
            </Link>
            <br></br>
            <Link to = "/news">
                <button className="modalLink">Новини</button>
            </Link>
        </Modal.Body>
      </Modal>
    </div>
    )
}

export default Header;