import React from 'react';
import Header from '../Header.js';
import './Standpoint.css';
import { Link } from 'react-router-dom';
import health from '../../assets/health.png';
import economy from "../../assets/economy.jpg";
import world from "../../assets/world.svg";
import ecology from "../../assets/ecology.svg";
import securtiy from "../../assets/security.svg";
import logo from "../../assets/kod-logo.svg"
const Standpoint =() =>{
return(
    <div className="standpointContainer">
      <Header></Header>
      <div className = 'subCatContainer'>
        <div className = 'subContainer'>
          <img src = {economy}></img>
          <Link to ="/economy" className = "link"><p>Икономика</p></Link>
        </div>

        <div className = 'subContainer'>
          <img src = {health}></img>
          <Link to ="/healthcare" className = "link"><p>Здравеопазване</p></Link>
        </div>

        <div className = 'subContainer'>
          <img src = {world}></img>
          <Link to ="/social" className = "link"><p>Социална сфера</p></Link>
        </div>

        <div className = 'subContainer'>
          <img src = {securtiy}></img>
          <Link to ="/securtiy" className = "link"><p>Сигурност</p></Link>
        </div>

        <div className = 'subContainer'>
          <img src = {ecology}></img>
          <Link to ="/ecology" className = "link"><p>Екология</p></Link>
        </div>

      </div>
        {/* make this reusable used in everywhere */}
      <div className="links">
        <a href="">Поверителност и Условия</a>
        <a href="">Контакти</a>
        <a href="https://www.facebook.com/about.kod">Facebook</a>
        <a href="/"><img src ={logo}></img></a>
      </div>
    </div>
    )
}

export default Standpoint;