import React from 'react';
import AdminHeader from '../adminHeader/adminHeader.js';
import Footer from "../../Reusable/Footer.js";
import './adminHome.css';

const adminHome =() =>{
return(
    <div className="contactContainer">
      <AdminHeader></AdminHeader>
      <div className = "guideContainer">
        <p style ={{textAlign: "center"}}>Упътвания:</p>
        <ul>
          <li className = "listItem">Позиции: При попълване на форума в суб секцията Позиции,дадената позиция ще може да се вижда от всички които използват сайта.</li>
          <li className = "listItem">Новини: При попълване на форума в суб секцията Новини,дадената новина ще може да се вижда от всички които използват сайта.</li>
          <li className = "listItem" style = {{marginBottom: "1em"}}>Записани: В тази суб секция може да се видят данните на хората, които са се записали като са си дали имейла.</li>
        </ul>
      </div>
      <Footer></Footer>
    </div>
    )
}

export default adminHome;