import React,{ useEffect } from 'react';
import AdminHeader from '../adminHeader/adminHeader.js';
import './adminSubscribers.css';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useState } from "react";


class Subscribers extends React.Component{
  constructor(props){
    super(props);
    this.state = {name: [], phone: [], emails: [],subscribers: [],header: ["ID","Name","Phone","Email"]}
    this.getSubscribers = this.getSubscribers.bind(this);
  }
  getSubscribers = () =>{
    let currentComponent = this;
    const db = firebase.firestore();
  
    let myPostsArr = [];
    db.collection('subscribers').get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            myPostsArr.push(doc.data());
        });
    }).then(function (){
        for(let i = 0 ; i< myPostsArr.length;i++){
            currentComponent.setState(state => ({
                name: state.name.concat([myPostsArr[i].Name]),
                phone: state.phone.concat([myPostsArr[i].Phone]),
                emails: state.emails.concat([myPostsArr[i].Email]),
                subscribers: state.subscribers.concat({id:i, name:myPostsArr[i].Name, age:myPostsArr[i].Phone, email:myPostsArr[i].Email})
            }));
        }
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });
}
componentDidMount() {
    this.getSubscribers();
}

renderTableData() {
  return this.state.subscribers.map((student, index) => {
     const { id, name, age, email } = student //destructuring
     return (
        <tr key={id}>
           <td>{id}</td>
           <td>{name}</td>
           <td>{age}</td>
           <td>{email}</td>
        </tr>
     )
  })
}
renderTableHeader() {
  return (
    <tr>
       <td>ID</td>
       <td>Name</td>
       <td>Phone</td>
       <td>Email</td>
    </tr>
 )
}
  render(){
  return(
    <div className="signUpContainer">
      <AdminHeader></AdminHeader>
      <div className = "subscribersContainer">
        <table id='students'>
               <tbody>
                  {this.renderTableHeader()}  
                  {this.renderTableData()}
               </tbody>
            </table>
      </div>
    </div>
    )
  }
};

export default Subscribers;