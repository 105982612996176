import React from 'react';
import './Footer.css';
import logo from '../../assets/kod-logo.svg';

const Footer =() =>{
return(
    
<div className="links">
        <a href="">Поверителност и Условия</a>
        <a href="">Контакти</a>
        <a href="https://www.facebook.com/about.kod">Facebook</a>
        <a href="/"><img src ={logo}></img></a>
      </div>
    )
}

export default Footer;