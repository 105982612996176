import React from 'react';
import './Login.css';
import firebase from "firebase";
import { login } from './utils';
import { useHistory } from "react-router-dom";


const Login =() =>{
    let history = useHistory();
    const HandleLogin = () =>{
        
        const txtEmail = document.getElementById('txtEmail');
        const txtPassword = document.getElementById('txtPassword');
        const btnLogin = document.getElementById('login');
    
        btnLogin.addEventListener('click', e =>{
        const email = txtEmail.value;
        const pass = txtPassword.value;
        const auth = firebase.auth();
        const promise =auth.signInWithEmailAndPassword(email,pass);
        promise.catch(e=> console.log(e.message));
    
        firebase.auth().onAuthStateChanged(firebaseUser =>{
            if(firebaseUser){
                login();
                history.push("/adminHome");
            }
            else{
            console.log("not logged in");
            }
        })
        })
    }

    return(
        <div className="loginContainer">
            <input id = "txtEmail" className = "inputField" type="email" placeholder="Email"></input>

            <input id = "txtPassword" className = "inputField" type = "password" placeholder = "Password"></input>

            <button id= "login" onClick={HandleLogin}>Login</button>
        </div>
        )
    }
    
    export default Login;