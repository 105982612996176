import React from 'react';
import AdminHeader from '../adminHeader/adminHeader.js';
import "./adminNews.css";
import Footer from "../../Reusable/Footer.js";
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useState, useEffect} from "react";
import {app} from "../../base.js";
import { useHistory } from "react-router-dom";

const  db = app.firestore();

const AdminNews =() =>{

  let history = useHistory();

  const [postsNumber, setNumber] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  useEffect(()=>{
    firebase.app();

    const db = firebase.firestore();
    db.collection("news").get().then(function(querySnapshot) {      
      setNumber(querySnapshot.size + 1);
    });
  })

  async function onFileChange (event) {
    // Update the state
    
    setSelectedFile(event.target.files[0]);
    const file = event.target.files[0];
    const storageRef = app.storage().ref();
    const fileRef = storageRef.child(file.name);
    await fileRef.put(file);
    setFileUrl(await fileRef.getDownloadURL());
  };

  // On file upload (click the upload button)
  // function onFileUpload (e){

  //   const storageRef = app.storage().ref();
  //   // Create an object of formData
  //   const formData = new FormData();
  
  //   // Update the formData object
  //   formData.append(
  //     "myFile",
  //     selectedFile,
  //     selectedFile.name
  //   );
  // };

  function handleSubmit() {

    firebase.app(); // if already initialized, use that one
    const db = firebase.firestore();
    //get number of posts in news
    db.collection("news").get().then(function(querySnapshot) {      
      setNumber(querySnapshot.size + 1);
  });
  db.collection('news').doc(`${postsNumber}`).set({
      title: document.getElementById("title").value,
      text: document.getElementById("text").value,
      url: fileUrl,
  }).then(function () {
      console.log("Document added successfully");
      history.push("/adminHome");
  }).catch(function (err) {
    console.log(err);
  })
    
  }

return(
    <div className="adminNews">
      <AdminHeader></AdminHeader>
      <div className = "adminDivStyle">
        <label style ={{marginRight: "0.8em"}}>Заглавие: </label>
        <input type= "text" id= "title" style = {{width: "17.5em"}}></input>
      </div>
      <div className = "adminDivStyle">
        <label style = {{alignSelf: "center"}}>Текст поле:</label>
        <textarea id= "text" rows="10" cols="41" style={{fontSize: "0.7em",maxWidth: "100%"}}></textarea>
      </div>
      <div className = "adminDivStyle">
        <label>Снимка Url:</label>
        <input style = {{width: "17.5em"}} type= "text" id= "picture"></input>
        <div>
                <input type="file" onChange={onFileChange}/>
                {/* <button onClick={onFileUpload}>
                  Upload!
                </button> */}
            </div>
      </div>
      <button className = "doneBtn" onClick ={handleSubmit} >Готово</button>
      <Footer></Footer>
    </div>
    )
}

export default AdminNews;